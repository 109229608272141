import * as React from "react"
import Template from "components/template";
import * as config from 'utils/configs/efadjointevirtuelle';

// markup
const EfadjointevirtuellePage = () => {
  return (
    <Template config={config} />
  )
}

export default EfadjointevirtuellePage
